<template>
  <div class="tariffs-table">
    <tags-filter :search="search" @search-server="searchDisk($event)"></tags-filter>
    <base-table
      :columns="tableHead"
      :list="paginList"
      :sort="sort"
      class="tariffs-table__table"
      @sort-change="onSortChange"
    >
      <tariffs-table-disk-row
        v-for="item in paginList"
        :key="item.id"
        :columns="tableHead"
        :filtred-search="searchFilter"
        :item="item"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
    <NewPagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="base"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTableDiskRow from './TariffsTableDiskRow';
import NewPagination from '@/components/Pagination/NewPagination';
import TagsFilter from '@/layouts/Stack/components/TagsFilter';
import pagination from '@/mixins/pagination';

export default {
  name: 'TariffsTableDisk',
  components: {
    BaseTable,
    TagsFilter,
    TariffsTableDiskRow,
    NewPagination,
  },
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [pagination],
  data() {
    return {
      search: '',
      searchFilter: '',
      tableHead: [
        {
          key: 'name',
          label: this.$t('tableHead.name'),
          style: {
            width: '130px',
            maxWidth: '230px',
          },
          sort: {
            prop: 'name',
            order: 'asc',
          },
        },
        {
          key: 'boot',
          label: this.$t('tableHead.boot'),
          style: {
            width: '50px',
          },
        },
        {
          key: 'inst',
          label: this.$t('tableHead.inst'),
          style: {
            width: '80px',
            maxWidth: '230px',
          },
        },
        {
          key: 'type',
          label: this.$t('tableHead.type'),
          style: {
            width: '80px',
          },
        },
        {
          key: 'size',
          label: this.$t('tableHead.size'),
          style: {
            width: '84px',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '84px',
          },
          sort: {
            prop: 'status',
            order: 'asc',
          },
        },
        {
          key: 'created',
          label: this.$t('tableHead.created'),
          style: {
            width: '40px',
            maxWidth: '100px',
          },
          sort: {
            prop: 'created_at',
            order: 'asc',
          },
        },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            width: '44px',
          },
        },
      ],
      stepOptions: [10, 25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 10,
      },
    };
  },
  computed: {
    // list() {
    //   return this.$store.state.moduleStack.servers;
    // },
    list() {
      if (this.$route.query.value)
        return this.$store.getters['moduleStack/GET_SORTED_LIST_DISKS'].filter(x =>
          x.name.toLowerCase().includes(this.$route.query.value.toLowerCase())
        );
      else return this.$store.getters['moduleStack/GET_SORTED_LIST_DISKS'];
    },
    sort() {
      return this.$store.state.moduleStack.sort.disk;
    },
    paginList() {
      if (this.pagin.step === 'all') return this.list;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.list.slice(start, end);
    },
  },
  watch: {
    sort: {
      handler(val, old) {
        this.updateSort(val);
      },
      deep: true,
    },
    '$route.query.value': {
      handler: function (val) {
        this.searchFilter = val;
        this.search = val;
      },
      immediate: true,
    },
    searchFilter: {
      handler(val) {
        this.setFilter(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSort(this.sort);
    this.search = '';
    this.setFilter(this.$route.query.value);
  },
  methods: {
    setFilter(val) {
      this.searchFilter = val;
      this.search = val;
      this.$router
        .push({
          name: 'containerDisk',
          query: { filter_by: 'name', value: val },
        })
        .catch(() => {});
    },
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    searchDisk(event) {
      this.searchFilter = event;
    },
    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit('moduleStack/SET_DISK_SORT', { prop, order });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "tableHead": {
      "name": "Имя диска",
      "type": "Тип",
      "boot": "Загрузочный",
      "inst": "Сервер",
      "size": "Размер",
      "created": "Создан",
      "config": "Конфигурация",
      "status": "Статус",
      "cost": "Цена",
      "menu": "Меню"
    },
    "activeUnder": "До {date}",
    "selectAll": "Выбрать все",
    "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
    "prolong": "Продлить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;

  &__tools {
    flexy(space-between, center);
    margin-bottom: 1.5rem;

    &-end {
      flexy(flex-end, center);
    }

    &-note {
      margin-right: 2.5rem;
    }
  }

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
