import NewDisk from '@/layouts/Stack/components/NewDisk';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, storeMixin, modals],
  data() {
    return {
      data: {},
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {
    quota() {
      return this.$store.getters['moduleStack/aviableSpaseVolume'];
    },
    projectId() {
      return this.$store.getters['moduleStack/projectId'];
    },
  },
  methods: {
    newDisk: function (instance) {
      const that = this;
      return new Promise(() => {
        const selfName = 'newDisk';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: NewDisk,
          closeOnBackdrop: false,
          props: {
            quota: this.quota,
            size: 5,
            instance: instance,
          },
          text: this.$t('sure.text'),
          on: {
            change: data => {
              instance = data;
              console.log(data);
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            // centered: true,
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  this.addNewDisk(instance)
                    .then(async data => {
                      // console.log(data);
                      this.newFetch();
                      that.$modals.close();
                      this.showResModal('Диск успешно создан.');

                      this.data = { disk: data.volume.id };
                      // console.log(this.data);
                    })
                    .catch(e => {
                      this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    // async updateDiskStatusAttach(callback, payload) {
    //   this.timerCnt = 1;
    //   this.timerId = setTimeout(() => {
    //     this.$store
    //       .dispatch('moduleStack/checkDiskStatus', {
    //         disk: this.id,
    //         type: 'volumes',
    //         silent: true,
    //       })
    //       .then(async data => {
    //         if (!['in-use'].includes(data)) {
    //           this.res = data;
    //           await this.updateDiskStatusAttach(callback, payload);
    //         } else {
    //           this.timerCnt = 1;
    //           this.isProcessing = false;
    //           clearTimeout(this.timerId);
    //           this.res = data;
    //           return 'success';
    //         }
    //       });
    //   }, 1000 * this.timerCnt);
    // },
    addNewDisk(payload) {
      return this.$store.dispatch('moduleStack/createDisk', payload).catch(e => {
        this.showError(e);
      });
    },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     confirm: {
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
